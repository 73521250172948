import { Header } from './components/header/Header';
import { PanelHandler } from './components/panelHandler/PanelHandler';

function App() {
	return (
		<>
			<Header />
			<PanelHandler />
		</>
	);
}

export default App;
