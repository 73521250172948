import afd from '../../assets/album_artworks/afd.jpg'
import afd_hq from '../../assets/album_artworks/afd_hq.jpg'
import aknf from '../../assets/album_artworks/aknf.jpg'
import aknf_hq from '../../assets/album_artworks/aknf_hq.jpg'
import americana from '../../assets/album_artworks/americana.jpg'
import americana_hq from '../../assets/album_artworks/americana_hq.jpg'
import anima from '../../assets/album_artworks/anima.jpg'
import anima_hq from '../../assets/album_artworks/anima_hq.jpg'
import audioslave from '../../assets/album_artworks/audioslave.jpg'
import audioslave_hq from '../../assets/album_artworks/audioslave_hq.jpg'
import cani from '../../assets/album_artworks/cani.jpg'
import cani_hq from '../../assets/album_artworks/cani_hq.jpg'
import cfh_hq from '../../assets/album_artworks/cfh_hq.jpg'
import cfh from '../../assets/album_artworks/cfh.jpg'
import dirt from '../../assets/album_artworks/dirt.jpg'
import dirt_hq from '../../assets/album_artworks/dirt_hq.jpg'
import dsotm from    '../../assets/album_artworks/dsotm.jpg'
import dsotm_hq from '../../assets/album_artworks/dsotm_hq.jpg'
import elephant from '../../assets/album_artworks/elephant.jpg'
import elephant_hq from '../../assets/album_artworks/elephant_hq.jpg'
import fhd from '../../assets/album_artworks/fhd.jpg'
import fhd_hq from '../../assets/album_artworks/fhd_hq.jpg'
import good_am from '../../assets/album_artworks/good_am.jpg'
import good_am_hq from '../../assets/album_artworks/good_am.jpg'
import ht from '../../assets/album_artworks/ht.jpg'
import ht_hq from '../../assets/album_artworks/ht_hq.jpg'
import igor from '../../assets/album_artworks/igor.jpg'
import igor_hq from '../../assets/album_artworks/igor_hq.jpg'
import issues from '../../assets/album_artworks/issues.jpg'
import issues_hq from '../../assets/album_artworks/issues_hq.jpg'
import kida from '../../assets/album_artworks/kid_a.jpg'
import kida_hq from '../../assets/album_artworks/kid_a_hq.jpg'
import ksg from '../../assets/album_artworks/ksg.jpg'
import ksg_hq from '../../assets/album_artworks/ksg_hq.jpg'
import lateralus from '../../assets/album_artworks/lateralus.jpg'
import lateralus_hq from '../../assets/album_artworks/lateralus_hq.jpg'
import lz4 from '../../assets/album_artworks/lz4.jpg'
import lz4_hq from '../../assets/album_artworks/lz4_hq.jpg'
import mbdtf from '../../assets/album_artworks/mbdtf.jpg'
import mbdtf_hq from '../../assets/album_artworks/mbdtf_hq.jpg'
import mcatis from '../../assets/album_artworks/mcatis.jpg'
import mcatis_hq from '../../assets/album_artworks/mcatis_hq.jpg'
import mm from '../../assets/album_artworks/mm.jpg'
import mm_hq from '../../assets/album_artworks/mm_hq.jpg'
import mop from '../../assets/album_artworks/mop.jpg'
import mop_hq from '../../assets/album_artworks/mop_hq.jpg'
import mtn from '../../assets/album_artworks/mtn.jpg'
import mtn_hq from '../../assets/album_artworks/mtn_hq.jpg'
import nevermind from '../../assets/album_artworks/nevermind.jpg'
import nevermind_hq from '../../assets/album_artworks/nevermind_hq.jpg'
import paranoid from '../../assets/album_artworks/paranoid.jpg'
import paranoid_hq from '../../assets/album_artworks/paranoid_hq.jpg'
import ratm from '../../assets/album_artworks/ratm.jpg'
import ratm_hq from '../../assets/album_artworks/ratm_hq.jpg'
import rb from '../../assets/album_artworks/rb.jpg'
import rb_hq from '../../assets/album_artworks/rb_hq.jpg'
import requiem from '../../assets/album_artworks/requiem.jpg'
import requiem_hq from '../../assets/album_artworks/requiem_hq.jpg'
import sftd from '../../assets/album_artworks/sftd.jpg'
import sftd_hq from '../../assets/album_artworks/sftd_hq.jpg'
import slipknot from '../../assets/album_artworks/slipknot.jpg'
import slipknot_hq from '../../assets/album_artworks/slipknot_hq.jpg'
import spiderland from '../../assets/album_artworks/spiderland.jpg'
import spiderland_hq from '../../assets/album_artworks/spiderland_hq.jpg'
import superunknown from '../../assets/album_artworks/superunknown.jpg'
import superunknown_hq from '../../assets/album_artworks/superunknown_hq.jpg'
import tdb from '../../assets/album_artworks/tdb.jpg'
import tdb_hq from '../../assets/album_artworks/tdb_hq.jpg'
import thcm from '../../assets/album_artworks/thcm.jpg'
import thcm_hq from '../../assets/album_artworks/thcm.jpg'
import thedownwardspiral from '../../assets/album_artworks/the_downward_spiral.jpg'
import thedownwardspiral_hq from '../../assets/album_artworks/the_downward_spiral_hq.jpg'
import toxicity from '../../assets/album_artworks/toxicity.jpg'
import toxicity_hq from '../../assets/album_artworks/toxicity_hq.jpg'
import tpab from '../../assets/album_artworks/tpab.jpg'
import tpab_hq from '../../assets/album_artworks/tpab_hq.jpg'
import white_pony_hq from '../../assets/album_artworks/white_pony_hq.jpg'
import white_pony from '../../assets/album_artworks/white_pony.jpg'
import wpstiatwin from '../../assets/album_artworks/whatever_people_say_that_I_am_thats_what_Im_not.jpg'
import wpstiatwin_hq from '../../assets/album_artworks/whatever_people_say_that_I_am_thats_what_Im_not.jpg'
export type album_info = {
    src: string,
    hqsrc: string,
    title: string
    artist: string
    year: number
}


export const album_list: album_info[] = [ 
    {
        src: lateralus,
        hqsrc: lateralus_hq,
        title: 'Lateralus',
        artist: 'Tool',
        year: 2001
    },
    {
        src: kida,
        hqsrc: kida_hq,
        title: 'Kid A',
        artist: 'Radiohead',
        year: 2000
    },
    {
        src: wpstiatwin,
        hqsrc: wpstiatwin_hq,
        title: "Whatever People Say I Am, That's What I'm Not",
        artist: 'Arctic Monkeys',
        year: 2006
    },
    {
        src: thedownwardspiral,
        hqsrc: thedownwardspiral_hq,
        title: 'The Downward Spiral',
        artist: 'Nine Inch Nails',
        year: 1994
    },
    {
        src: fhd,
        hqsrc: fhd_hq,
        title: '2014 Forest Hills Drive',
        artist: 'J. Cole',
        year: 2014
    },
    {
        src: good_am,
        hqsrc: good_am_hq,
        title: 'GO:OD AM',
        artist: 'Mac Miller',
        year: 2015
    },
    {
        src: slipknot,
        hqsrc: slipknot_hq,
        title: 'Slipknot',
        artist: 'Slipknot',
        year: 1999
    },
    {
        src: mcatis,
        hqsrc: mcatis_hq,
        title: 'Mellon Collie and the Infinite Sadness',
        artist: 'The Smashing Pumpkins',
        year: 1995
    },
    {
        src: toxicity,
        hqsrc: toxicity_hq,
        title: 'Toxicity',
        artist: 'System of a Down',
        year: 2000
    },
    {
        src: tpab,
        hqsrc: tpab_hq,
        title: 'To Pimp a Butterfly',
        artist: 'Kendrick Lamar',
        year: 2015
    },
    {
        src: afd, 
        hqsrc: afd_hq,
        title: 'Appetite For Destruction',
        artist: "Guns'n Roses",
        year: 1987
    },
    {
        src: aknf,
        hqsrc: aknf_hq,
        title: 'All Killer No Filler',
        artist: 'Sum 41',
        year: 2001
    },
    {
        src: americana,
        hqsrc: americana_hq,
        title: 'Americana',
        artist: 'The Offspring',
        year: 1998
    },
    {
        src: audioslave,
        hqsrc: audioslave_hq,
        title: 'Audioslave',
        artist: 'Audioslave',
        year: 2002
    },
    {
        src: cfh,
        hqsrc: cfh_hq,
        title: 'Cowboys From Hell',
        artist: 'Pantera',
        year: 1990
    },
    {
        src: spiderland,
        hqsrc: spiderland_hq,
        title: 'Spiderland',
        artist: 'Slint',
        year: 1991
    },
    {
        src: white_pony,
        hqsrc: white_pony_hq,
        title: 'White Pony',
        artist: 'Deftones',
        year: 2000 
    },
    {
        src: tdb,
        hqsrc: tdb_hq,
        title: "Three Dollar Bill, Y'all $",
        artist: 'Limp Bizkit',
        year: 1997
    },
    {
        src: dirt,
        hqsrc: dirt_hq,
        title: 'Dirt',
        artist: 'Alice In Chains',
        year: 1992
    },
    {
        src: dsotm,
        hqsrc: dsotm_hq,
        title: 'The Dark Side of the Moon',
        artist: 'Pink Floyd',
        year: 1973
    },
    {
        src: elephant,
        hqsrc: elephant_hq,
        title: 'Elephant',
        artist: 'The White Stripes',
        year: 2003
    },
    {
        src: ht,
        hqsrc: ht_hq,
        title: 'Hybrid Theory',
        artist: 'Linkin Park',
        year: 2000
    },
    {
        src: igor,
        hqsrc: igor_hq,
        title: 'Igor',
        artist: 'Tyler, the Creator',
        year: 2019
    },
    {
        src: issues,
        hqsrc: issues_hq,
        title: 'Issues',
        artist: 'Korn',
        year: 1999
    },
    {
        src: mbdtf,
        hqsrc: mbdtf_hq,
        title: 'My Beautiful Dark Twisted Fantasy',
        artist: 'Ye',
        year: 2010
    },
    {
        src: lz4,
        hqsrc: lz4_hq,
        title: 'Led Zeppelin IV',
        artist: 'Led Zeppelin',
        year: 1971
    },
    {
        src: mm,
        hqsrc: mm_hq,
        title: 'The Marshall Mathers LP ',
        artist: 'Eminem',
        year: 2000
    },
    {
        src: ksg,
        hqsrc: ksg_hq,
        title: 'Kids See Ghosts',
        artist: 'Kids See Ghosts',
        year: 2018
    },
    {
        src: mop,
        hqsrc: mop_hq,
        title: 'Master Of Puppets',
        artist: 'Metallica',
        year: 1986
    },
    {
        src: mtn,
        hqsrc: mtn_hq,
        title: 'Mer De Noms',
        artist: 'A Perfect Circle',
        year: 2000
    },
    {
        src:  nevermind,
        hqsrc: nevermind_hq,
        title: 'Nevermind',
        artist: 'Nirvana',
        year: 1991
    },
    {
        src: paranoid,
        hqsrc: paranoid_hq,
        title: 'Paranoid',
        artist: 'Black Sabbath',
        year: 1970
    },
    {
        src: ratm,
        hqsrc: ratm_hq,
        title: 'Rage Against the Machine',
        artist: 'Rage Against the Machine',
        year: 1992
    },
    {
        src: rb,
        hqsrc: rb_hq,
        title: 'Royal Blood',
        artist: 'Royal Blood',
        year: 2014
    },
    {
        src: sftd,
        hqsrc: sftd_hq,
        title: 'Songs For The Deaf',
        artist: 'Queens of the Stone Age',
        year: 2002
    },
    {
        src: superunknown,
        hqsrc: superunknown_hq,
        title: 'Superunknown',
        artist: 'Soundgarden',
        year: 1994
    },
    {
        src: requiem,
        hqsrc: requiem_hq,
        title: 'Requiem',
        artist: 'Verdena',
        year: 2007
    },
    {
        src: thcm,
        hqsrc: thcm_hq,
        title: 'The Island Chainsaw Massacre',
        artist: 'Salmo',
        year: 2011
    },
    {
        src: cani,
        hqsrc: cani_hq,
        title: "Il sorprendente album d'esordio de I Cani",
        artist: 'I Cani',
        year: 2011
    },
    {
        src: anima,
        hqsrc: anima_hq,
        title: 'ANIMA',
        artist: 'Thom Yorke',
        year: 2019
    }
    
]