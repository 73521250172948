import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const Header = () => {
    return (
        <Container>
            <Row className="pt-5">
                <Col lg={12}>
                    <h1 style={{color: "white", textAlign: "center"}}>Matteo's Life</h1>
                </Col>
            </Row>
        </Container>
    )
}
