import { Card, Col, Container } from 'react-bootstrap'
import "./PanelHandler.css"
import { BigPanel } from '../bigPanel/BigPanel'
import { album_info, album_list } from '../../assets/album_info/album_list'
import { useState } from 'react';

function shuffle(array: album_info[]) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export const PanelHandler = () => {
    const [ showDescription, setShowDescription ] = useState(true)

    shuffle(album_list)

    return (
        <>
        { showDescription && 
        <Container className='mt-5'>
            <Col lg={12} xl={12} xxl={12}>
                <Card className="card">
                    <Card.Body>
                    Welcome to this personal space of mine. 
                    I tried (a lot is missing for sure) to list just one album from the artists that influenced me as a person and helped me to become the person that I am today. 
                    Music has always been an art that kept me creative and has guided me in every moment of my lifetime. 
                    At the moment, I'm always trying to discover passionate music that has something to say and is done with creativity and enjoyment. 
                    I see music as a journey to truly experience with passion, inspiration, and love. <br/>
                    I hope you embrace art as I do <br/>
                    - Matteo
                    </Card.Body>
                </Card>
            </Col>
        </Container>
        }
        <Container>
            <BigPanel albumList={album_list} showDescription={setShowDescription} />
        </Container>
        </>

    )
}