import { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { PanelImage } from '../panel/PanelImage'
import { motion } from 'framer-motion'
import { PanelInfo } from '../panelInfo/PanelInfo'
import { album_info } from '../../assets/album_info/album_list';


export const BigPanel = ({ albumList, showDescription }) => {
    const [ showImageInfo, setShowImageInfo ] = useState(false)
    const [ albumInfo, setAlbumInfo ] = useState({ src: '', title: '', artist: '', year: 0})
    const [ isDragging, setIsDragging ] = useState(false);
    const [ mousePosition, setMousePosition ] = useState({ x: 0, y: 0 });
    const [ mousePositionOnClick, setMousePositionOnClick ] =  useState({ x: 0, y: 0})

    const handleMouseMove = (event) => {
        setMousePosition({
            x: event.clientX,
            y: event.clientY
        });
    };

    const handleMouseDown = () => {
        setMousePositionOnClick(mousePosition)
    };
  
    const handleMouseUp = () => {
        mousePosition === mousePositionOnClick ? setIsDragging(false) : setIsDragging(true)
    };

    const handleClick = (album: album_info) => {
        if (!showImageInfo && !isDragging) {
            setShowImageInfo(true)
            setAlbumInfo(album)
        }
    }

    const comeBackToBigPanel = (value) => {
        setShowImageInfo(value)
    }

    const setShowDescription = (value) => {
        showDescription(value)
    }

    return (
        <>
        <Row className="mb-4">
            {showImageInfo ?
                <PanelInfo albumInfo={albumInfo} showPanel={comeBackToBigPanel} showDescription={setShowDescription} />
            :
            albumList.map((album:album_info) => {               
                return <Col className="mb-1 mt-3" xxs={2} xs={6} sm={6} md={4} lg={2}>
                    <motion.div
                        style={{borderRadius: 20}}
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                        onClick={() => handleClick(album)} 
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        drag
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        dragElastic={1}>
                        <PanelImage albumTitle={album.src} />
                    </motion.div>
                </Col>
            }
            )}
        </Row>
        </>
    )
}


