import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Col, Card } from 'react-bootstrap';

export const PanelInfo = ({ albumInfo, showPanel, showDescription }) => {

    const [ imageSelected, isImageSelected ] = useState(false)
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
    const [ isDragging, setIsDragging ] = useState(false);
    const [ mousePosition, setMousePosition ] = useState({ x: 0, y: 0 });
    const [ mousePositionOnClick, setMousePositionOnClick ] =  useState({ x: 0, y: 0})

    const handleMouseMove = (event) => {
        setMousePosition({
            x: event.clientX,
            y: event.clientY
        });
    };

    const handleMouseDown = () => {
        setMousePositionOnClick(mousePosition)
    };
  
    const handleMouseUp = () => {
        mousePosition === mousePositionOnClick ? setIsDragging(false) : setIsDragging(true)
    };


    const handleOnClick = () => {
        !isDragging ? showPanel(false) : showPanel(true)
    }

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    let breakpoint;
    
    if (screenWidth < 576) {
        breakpoint = 'xs';
    } else if (screenWidth >= 576 && screenWidth < 768) {
        breakpoint = 'sm';
    } else if (screenWidth >= 768 && screenWidth < 992) {
        breakpoint = 'md';
    } else if (screenWidth >= 992 && screenWidth < 1200) {
        breakpoint = 'lg';
    } else if (screenWidth >= 1200) {
        breakpoint = 'xl';
    }


    return (
        <>  
            {((breakpoint === 'xs' || breakpoint === 'sm')) && showDescription(false)}
            {((breakpoint !== 'xs' && breakpoint !== 'sm')) && showDescription(true)}
            {(breakpoint === 'xs' || breakpoint === 'sm') ?
            <>
                <Col className="mb-1 mt-3" xxs={2} xs={0} sm={0} md={3} lg={3}/>
                <Col className="mb-1 mt-3" xxs={2} xs={12} sm={12} md={6} lg={6}>
                    {!imageSelected ?  
                        <motion.img 
                            style={{ borderRadius: 20, width: '100%', height: '100%', objectFit: 'contain' }}
                            src={ albumInfo.hqsrc }
                            initial={{ 
                                rotateY: 0,
                                opacity: 1 
                            }}
                            animate={{ 
                                rotateY: 180, 
                                opacity: 0 
                            }}
                            transition={{
                                duration: 1
                            }}
                            onAnimationComplete={() => isImageSelected(true)}
                            onClick={() => isImageSelected(true)}
                        />
                    :
                    <motion.div
                        initial={{ 
                            opacity: 0 
                        }}
                        animate={{ 
                            opacity: 1 
                        }}
                        transition={{
                            duration: 1
                        }}
                        drag
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        dragElastic={1}
                        onClick={handleOnClick}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        >
                        <Card 
                            style={{ backgroundColor:'black', color: 'white' }}>
                            <Card.Body 
                                style={{textAlign: 'center'}}>
                                Album: {albumInfo.title} <br/>
                                Artist: {albumInfo.artist} <br/>
                                Year: {albumInfo.year} 
                            </Card.Body>
                        </Card>
                    </motion.div>
                    }
                </Col> 
                <Col className="mb-1 mt-3" xxs={2} xs={0} sm={0} md={3} lg={3}/>
            </> :
            <>
                <Col className="mb-1 mt-3" xxs={2} xs={12} sm={12} md={6} lg={6}>
                    <motion.img 
                        style={{ borderRadius: 20, width: '100%', height: '100%', objectFit: 'contain' }}
                        src={ albumInfo.hqsrc }
                        initial={{ 
                            opacity: 0 
                        }}
                        animate={{ 
                            opacity: 1 
                        }}
                        transition={{
                            duration: 1
                        }}
                        onClick={handleOnClick}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        drag
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        dragElastic={1}
                    />
                </Col>
                <Col className="mb-1 mt-3" xxs={2} xs={12} sm={12} md={6} lg={6}>
                    <motion.div
                        initial={{ 
                            opacity: 0 
                        }}
                        animate={{ 
                            opacity: 1 
                        }}
                        transition={{
                            duration: 1
                        }}
                        drag
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        dragElastic={1}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        >
                        <Card 
                            onClick={handleOnClick}
                            style={{ backgroundColor:'black', color: 'white' }}>
                            <Card.Body 
                                style={{textAlign: 'center'}}>
                                Album: {albumInfo.title} <br/>
                                Artist: {albumInfo.artist} <br/>
                                Year: {albumInfo.year} 
                            </Card.Body>
                        </Card>
                    </motion.div>
                </Col>
            </>}
        </>
    )
}